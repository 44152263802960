import ChevronLeft24 from './ChevronLeft24';
import ChevronLeft16 from './ChevronLeft16';
type Props = {
  size?: 16 | 24;
  color?: string;
  [key: string]: any;
};

const assets = {
  24: ChevronLeft24,
  16: ChevronLeft16,
};

export default ({ size = 16, color, ...args }: Props) => {
  const Asset = assets[size];
  return (
    <span className="oksoon-icon oksoon-icon--ChevronLeft" {...args}>
      <Asset color={color} />
    </span>
  );
};
