import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import * as R from 'ramda';
import { v4 as uuidv4 } from 'uuid';
import IconChevronLeft from '@shared/svg/ChevronLeft';
import IconChevronRight from '@shared/svg/ChevronRight';
import { decoder } from '@shared/utils/jwt';
import { unixtime } from '@shared/utils/num';
import analytics from '~/analytics';
import KakaoIcon from '~/svg/Kakao';
import {
  ACCESS_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
  DEVICE_STORAGE_KEY,
  USER_NAME,
  USER_THUMBNAIL,
} from '~/utils/constants';
import {
  fetchDeviceLogin,
  fetchKakaoLogin,
  fetchKakaoToken,
  fetchKakaoUser,
  fetchRefreshingAccessToken,
} from './fetches';
import { useSwipe, getSwipeOrder, NEXT, PREV } from './hooks';
import statistics from './statistics';
import * as styles from './styles';
import type { Direction } from './hooks';

const SWIPER_IMAGE_NUM = 4;

export default function Home() {
  const router = useRouter();
  const [cookies, setCookies] = useCookies([
    ACCESS_TOKEN_KEY,
    REFRESH_TOKEN_KEY,
    USER_NAME,
    USER_THUMBNAIL,
  ]);
  const [isLoading, setLoading] = useState(false);

  const handleSwipeAnalytics = (direction: Direction) => {
    const curr =
      direction === PREV
        ? carouselState.pos
        : carouselState.pos + 2 * (carouselState.pos >= 2 ? -1 : 1);
    const prev =
      direction === PREV
        ? curr + 1 >= SWIPER_IMAGE_NUM
          ? 0
          : curr + 1
        : curr - 1 < 0
        ? SWIPER_IMAGE_NUM - 1
        : curr - 1;
    analytics.trigger(
      statistics('guide', 'swipe', {
        area: 'mainimg',
        dcomp: 'guide',
        page: `${prev}_${curr}`,
      })
    );
  };
  const {
    handlers,
    state: carouselState,
    slide,
  } = useSwipe(handleSwipeAnalytics);

  const goToChat = () => {
    router.push(`/chat`);
  };

  const login = () => {
    const { code } = R.pipe(
      R.slice(1, Infinity),
      R.split('&'),
      R.reduce((acc, elem) => {
        const [key, value] = elem.split('=');
        return { ...acc, [key]: value };
      }, {} as Record<string, string>)
    )(window.location.search);
    if (!!code) {
      (async () => {
        setLoading(() => true);
        const {
          access_token: accessToken,
          access_token_exp: accessTokenExpiration,
          refresh_token: refreshToken,
          refresh_token_exp: refreshTokenExpiration,
        } = await getAccessToken();
        const { response: tokenResponse, error: tokenError } =
          await fetchKakaoToken({
            code,
            redirection: window.location.origin,
          });
        let user: Record<string, any> = {};
        if (!tokenError) {
          user = await fetchKakaoUser({
            accessToken: tokenResponse.access_token,
          });
          const { nickname, thumbnail_image_url: thumbnail } =
            user.kakao_account.profile ?? {};
          if (!!nickname) {
            setCookies(USER_NAME, nickname, { maxAge: refreshTokenExpiration });
          }
          if (!!thumbnail) {
            setCookies(USER_THUMBNAIL, thumbnail, {
              maxAge: refreshTokenExpiration,
            });
          }

          const { error } = await fetchKakaoLogin({
            kakaoAccessToken: tokenResponse.access_token,
            accessToken,
            user,
          });
          const todayUnixTime = unixtime(new Date());
          if (!error) {
            analytics.trigger(
              statistics('login', 'success', {
                area: 'btmbutton',
                dcomp: 'login',
                comm_type: 'kakao',
                user_token: decoder(accessToken).user_token ?? '',
              })
            );
            setCookies(ACCESS_TOKEN_KEY, accessToken, {
              maxAge: accessTokenExpiration - todayUnixTime,
              path: '/',
            });
            setCookies(REFRESH_TOKEN_KEY, refreshToken, {
              maxAge: refreshTokenExpiration - todayUnixTime,
              path: '/',
            });
            goToChat();
          } else {
            alert(
              `네트워크 연결에 문제가 발생하였습니다. 나중에 다시 시도해주세요[${
                error.response?.status ?? 500
              }]`
            );
          }
          setLoading(() => false);
        } else {
          setLoading(() => false);
        }
      })();
    } else {
      setLoading(() => false);
    }
  };

  const getAccessToken = async () => {
    let deviceId = localStorage.getItem(DEVICE_STORAGE_KEY);
    if (!deviceId) {
      const newDeviceId = uuidv4();
      localStorage.setItem(DEVICE_STORAGE_KEY, newDeviceId);
      deviceId = newDeviceId;
    }

    const { response, error } = await fetchDeviceLogin({ id: deviceId });
    return !error ? response ?? {} : {};
  };

  const refreshAccessToken = () => {
    (async () => {
      setLoading(() => true);
      const { response, error } = await fetchRefreshingAccessToken({
        refreshToken: cookies[REFRESH_TOKEN_KEY],
      });
      if (!error) {
        const { access_token: accessToken, expiration } = response;
        setCookies(ACCESS_TOKEN_KEY, accessToken, {
          maxAge: expiration,
          path: '/',
        });
        setLoading(() => false);
        goToChat();
      } else {
        login();
      }
    })();
  };

  const init = () => {
    if (cookies[ACCESS_TOKEN_KEY]) {
      goToChat();
      return;
    }

    analytics.trigger(statistics('__view__', 'view', {}));
    if (cookies[REFRESH_TOKEN_KEY]) {
      // todo: check refresh token expiration
      refreshAccessToken();
    } else {
      login();
    }
  };
  useEffect(init, []);

  const handleKakaoLoginClick = () => {
    analytics.trigger(
      statistics('login', 'click', {
        area: 'btmbutton',
        dcomp: 'login',
        comm_type: 'kakao',
      })
    );
    window.open(
      `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.NEXT_PUBLIC_KAKAO_CLIENT_ID}&response_type=code&redirect_uri=${window.location.origin}`,
      '_self'
    );
  };

  return (
    <div css={styles.container}>
      <div css={styles.wrapper}>
        <div {...handlers} css={styles.carouselArrowWrapper}>
          <button onClick={() => slide(PREV)}>
            <IconChevronLeft size={24} />
          </button>
          <div className="swiper-wrapper">
            <div
              className="swiper-container"
              css={styles.swiper(carouselState.sliding, carouselState.dir)}
            >
              {R.map(
                (i) => (
                  <div
                    key={i}
                    className="swiper-slot"
                    css={styles.carouselSlot(
                      getSwipeOrder(i, carouselState.pos, 4)
                    )}
                  >
                    <img src={`/assets/guide${i + 1}.png`} alt="" />
                  </div>
                ),
                [0, 1, 2, 3]
              )}
            </div>
          </div>
          <button onClick={() => slide(NEXT)}>
            <IconChevronRight size={24} />
          </button>
        </div>
        <div css={styles.carouselIndicatorWrapper}>
          <ol className="swiper-indices">
            {R.map(
              (i) => (
                <li
                  key={i}
                  css={styles.carouselIndicator(i === carouselState.pos)}
                />
              ),
              [3, 0, 1, 2]
            )}
          </ol>
        </div>
        <div css={styles.loginWrapper}>
          <button onClick={handleKakaoLoginClick} disabled={isLoading}>
            <KakaoIcon /> 카카오로 {isLoading ? ' 접속하는 중...' : '시작하기'}
          </button>
        </div>
      </div>
    </div>
  );
}
