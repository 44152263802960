export default (key: string, action: string, params: any = {}) => {
  const { frame, ...res } = params;
  const isViewStat = /__(.+?)__/.test(key);
  return {
    path: `/stat/${key.replace(/__(.+?)__/g, frame)}/${action}`.replace(
      /\/\//g,
      '/'
    ),
    params: isViewStat ? res : params,
  };
};
