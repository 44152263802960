import { css } from '@emotion/react';
import { PREV } from './hooks';

export const container = css`
  position: relative;
  height: 100%;
  background: var(--white);
`;

export const wrapper = css`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 768px;
  height: 100%;
  margin: 0 auto;
  user-select: none;

  & > div {
    width: 100%;
  }

  .swiper-wrapper {
    width: 100%;
    overflow: hidden;
  }
`;

export const swiper = (sliding: boolean, direction: string) => css`
  display: flex;
  transition: ${sliding ? 'none' : 'transform 1s ease'};
  transform: ${(() => {
    if (!sliding) return 'translateX(calc(-100%))';
    if (direction === PREV) return 'translateX(calc(2 * -100%))';
    return 'translateX(0%)';
  })()};
`;

export const carouselSlot = (order: number) => css`
  display: flex;
  flex: 1 0 100%;
  flex-basis: 100%;
  justify-content: center;
  order: ${order};

  & > img {
    pointer-events: none;
  }
`;

export const carouselImage = (index: number) => css`
  padding-top: 530px;
  background-image: url(assets/guide${index + 1}.png);
  background-size: cover;
`;

export const carouselArrowWrapper = css`
  display: flex;
  align-items: center;

  & > button {
    width: 40px;
    height: 40px;

    @media screen and (max-width: 768px) {
      display: none;
    }

    &:first-of-type {
      margin-left: 24px;
    }

    &:last-of-type {
      margin-right: 24px;
    }

    & > svg {
      display: block;
      width: 40px;
      height: 40px;
      color: var(--gray400);
    }
  }
`;

export const carouselIndicatorWrapper = css`
  display: flex;
  align-items: center;
  justify-content: center;

  & > ol {
    display: flex;
    justify-content: center;
  }
`;

export const carouselIndicator = (active: boolean) => css`
  width: 6px;
  height: 6px;
  background-color: ${active ? 'var(--primary)' : 'rgba(67, 24, 255, 0.2)'};
  border-radius: 50%;
  transition-duration: 0.2s;
  transition-property: var(--transition-color);

  &:not(:first-of-type) {
    margin-left: 6px;
  }
`;

export const h1Wrapper = css`
  display: flex;
  align-items: center;

  .icon--beta {
    margin-left: 8px;
  }
`;

export const loginWrapper = css`
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  margin-top: 48px;

  button {
    display: flex;
    justify-content: center;
    padding: 16px 0;
    font-size: 14px;
    font-weight: 600;
    color: var(--gray800);
    background-color: var(--kakao-yellow);
    border: 1px solid rgba(43, 54, 116, 0.1);
    border-radius: 12px;

    &:disabled {
      cursor: wait;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
        #fee500;
    }

    & > svg {
      margin-top: -1px;
      margin-right: 8px;
    }
  }
`;
