import api from '@shared/api';

type KakaoLoginType = {
  accessToken: string;
  kakaoAccessToken: string;
  user: Record<string, any>;
};

export async function fetchKakaoLogin({
  accessToken,
  kakaoAccessToken,
  user,
}: KakaoLoginType) {
  const res = await api({
    url: '/api/login/kakao',
    method: 'POST',
    data: { user, accessToken: kakaoAccessToken },
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  return res;
}

type DeviceLoginParamType = {
  id: string;
};

export async function fetchDeviceLogin({ id }: DeviceLoginParamType) {
  const res = await api({
    url: '/api/login/device',
    method: 'POST',
    data: { id },
  });
  return res;
}

type KakaoTokenParamType = {
  code: string;
  redirection: string;
};

export async function fetchKakaoToken({
  code,
  redirection,
}: KakaoTokenParamType) {
  const res = await api({
    baseURL: `https://kauth.kakao.com`,
    url: `/oauth/token`,
    method: 'POST',
    data: {
      grant_type: 'authorization_code',
      client_id: process.env.NEXT_PUBLIC_KAKAO_CLIENT_ID,
      code,
      redirect_uri: redirection,
      client_secret: process.env.NEXT_PUBLIC_KAKAO_CLIENT_SECRET,
    },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  });
  return res;
}

type KakaoUserParamType = {
  accessToken: string;
};

export async function fetchKakaoUser({ accessToken }: KakaoUserParamType) {
  const { response, error } = await api({
    baseURL: `https://kapi.kakao.com`,
    url: `/v2/user/me`,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    },
  });
  return !error ? response : {};
}

type RefreshTokenParamType = {
  refreshToken: string;
};

export async function fetchRefreshingAccessToken({
  refreshToken,
}: RefreshTokenParamType) {
  const result = await api({
    url: `/api/login/refresh/access-token`,
    headers: { Authorization: `Bearer ${refreshToken}` },
  });
  return result;
}

export async function fetchRefreshingRefreshToken({
  refreshToken,
}: RefreshTokenParamType) {
  const result = await api({
    url: `/api/login/refresh/refresh-token`,
    headers: { Authorization: `Bearer ${refreshToken}` },
  });
  return result;
}
