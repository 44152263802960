import ChevronRight24 from './ChevronRight24';
import ChevronRight16 from './ChevronRight16';
type Props = {
  size?: 16 | 24;
  color?: string;
  [key: string]: any;
};

const assets = {
  24: ChevronRight24,
  16: ChevronRight16,
};

export default ({ size = 16, color, ...args }: Props) => {
  const Asset = assets[size];
  return (
    <span className="oksoon-icon oksoon-icon--ChevronRight" {...args}>
      <Asset color={color} />
    </span>
  );
};
