import { curry } from 'ramda';

/**
 * 숫자 3자리 마다 ,(콤마) 추가
 */
export const currency = (value: number | string) => {
  return String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

/**
 * @param {Date} date
 * @param {number} digit
 * @returns {number}
 * @description 10자리 유닉스 시간 반환
 */
export const unixtime = (date: Date, digit?: number) => {
  const today = date ? +date : +new Date();
  return today.toString().length === (digit ?? 10)
    ? today
    : Math.trunc(today / 1000);
};

/**
 *
 * @param {number} num
 * @returns {string}
 * @description 1자리 수를 2자리 수로 반환
 */
export const twoDigits = (num: number) => {
  return num < 10 ? `0${num}` : `${num}`;
};

/**
 * 범위내 무작위 숫자
 * @example
 * num.random(1, 10); // '8'
 */
export const random = curry((min: number, max: number): number => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
});
